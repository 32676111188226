<template>
  <DiaryContainer
    :title="title"
    :actions="actions"
    :gradient-type="'diary-detail'"
    max-width="480px"
  >
    <template v-slot:content>
      <div v-if="loading">
        <div class="page-segment">
          <div class="page-segment-content">
            <Skeleton
              class="margin-bottom-lg"
              height="30px"
              width="100px"
              border-radius="16px"
            />
            <Skeleton
              class="skeleton-row"
              height="200px"
              border-radius="16px"
            />
          </div>
        </div>
        <div v-for="index in 2" :key="index" class="page-segment">
          <div class="page-segment-content">
            <Skeleton
              class="margin-bottom-lg"
              height="21px"
              width="100px"
              border-radius="16px"
            />
            <div class="skeleton-row">
              <Skeleton
                v-for="i in 4"
                :key="i"
                height="39px"
                width="118px"
                border-radius="16px"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading">
        <div v-if="moodOption" class="page-segment">
          <div class="mood-container page-segment-content">
            <MoodButton class="mood" :option="moodOption" />
            <div
              class="heading margin-bottom-lg"
              v-text="optionText(moodOption.feeling)"
            />
            <div class="body" data-hj-suppress v-text="data.my_day" />
          </div>
        </div>
        <div v-if="data.activity_tags?.length > 0" class="page-segment">
          <div class="page-segment-content">
            <div
              class="content-title margin-bottom-lg"
              v-text="$translations.diary.detail.activities"
            />
            <div class="chips">
              <Chip
                size="pill"
                :text="emotion.name[lang]"
                :emoji="emotion.code"
                color="blue"
                v-for="(emotion, index) in data.activity_tags"
                :key="index"
              />
            </div>
          </div>
        </div>
        <div
          v-if="data.feeling_tags?.length > 0"
          class="page-segment margin-bottom-zero"
        >
          <div class="page-segment-content">
            <div
              class="content-title margin-bottom-lg"
              v-text="$translations.diary.detail.feelings"
            />
            <div class="chips">
              <Chip
                size="pill"
                :text="activity.name[lang]"
                color="purple"
                :emoji="activity.code"
                v-for="(activity, index) in data.feeling_tags"
                :key="index"
              />
            </div>
          </div>
        </div>
        <div
          v-if="entries?.length > 0"
          class="page-segment margin-bottom-zero"
        >
          <div class="page-segment-content">
            <div
              class="margin-top"
              v-if="entries && entries.length"
            >
              <div class="content-title text-gray-80 margin-bottom" v-text="$translations.diary.success.recommendations" />
              <div class="diary-entry-cards">
                <DisplayCardLarge
                  v-for="(item, index) in other"
                  :key="`other-${index}`"
                  :display-config="otherConfig(item)"
                  @click="onClick(item, 'other')"
                />
                <DisplayCardLarge
                  v-for="(item, index) in entries"
                  :key="index"
                  :display-config="displayConfig(item)"
                  :duration-icon="item.content_format.icon"
                  @click="onClick(item, 'entry')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </DiaryContainer>
</template>

<script>
import {DiaryContainer, Diary, Chip, Skeleton, DisplayCardLarge} from '@seliaco/red-panda'
import { moodBannerEmojis } from '@/views/diary/configuration/configuration'
import MoodButton from '@/views/diary/components/MoodButton'
import {DiaryEvents, ForYouEvent} from '@/types/events'
import MediaContentMixin from "@/mixins/media-content-mixin";
export default {
  name: 'EmotionDetail',
  mixins: [MediaContentMixin],
  components: {DisplayCardLarge, Skeleton, DiaryContainer, Chip, MoodButton },
  data () {
    return {
      lang: '',
      loading: false,
      data: {},
      actions: {
        back: { callback: () => this.$router.push({ name: 'Diary' }) },
        edit: {
          callback: () => {
            const data = {
              ...this.data,
              feeling_tex_area: this.data.my_day
            }
            localStorage.setItem('selia-diary-entry-form', JSON.stringify(data))
            const mood = moodBannerEmojis.find(
              (item) => item.score === this.data.feeling_score
            )
            this.$router.push({
              name: 'DiaryEntry',
              query: { mood: JSON.stringify(mood), action: 'edit' }
            })
            DiaryEvents.Diary_Click_Edit()
          }
        }
      },
      moodButton: moodBannerEmojis,
      entries: null,
      other: null
    }
  },
  created () {
    this.lang = this.$store.getters.language.lang
    this.getData()
  },

  computed: {
    title () {
      return (
        this.$moment.locale(this.lang) &&
        this.$moment(this.data.created_at).format('ll, hh:mm a')
      )
    },
    optionText () {
      return (feeling) => {
        const translations = this.$translations.diary
        const mood = translations['mood-banner'].feelings[feeling].toLowerCase()
        const title = translations.detail.feel.replace('{{mood}}', mood)

        return title
      }
    },
    moodOption () {
      return this.moodButton?.find(
        (option) => option.score === this.data.feeling_score
      )
    }
  },
  methods: {
    getData () {
      this.loading = true
      Diary.getEmotionEntry(this.$route.params.id)
        .then(async (data) => {
          this.data = data

          // request recommended entries
          await Diary.getForYouRecommendations(this.$route.params.id)
            .then(o => {
              this.entries = o.entries
              this.other = o.other
            })
        })
        .finally(() => (this.loading = false))
    },
    onClick (item, type) {
      switch (type){
        case 'other':
          DiaryEvents.diaryRecommendationClicked({
            type: 'APPOINTMENT',
            key: item.key,
            tags: null
          })
          this.$router.push(item.redirect_path)
          break
        case 'entry': {
          DiaryEvents.diaryRecommendationClicked({
            type: 'CONTENT',
            key: item.slug,
            tags: item.tags
          })
          const body = {
            page: this.$route.name,
            origin: 'Diario',
            component: 'Card',
            ...item
          }
          ForYouEvent.contentCardOpen(body)
          this.showDetail(item)
          break
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$squareSize: 56px

.mood-container
  position: relative
  z-index: 1
  .mood
    z-index: 2
    position: absolute
    height: $squareSize
    width: $squareSize
    background: white
    border: 2px solid var(--white)
    border-radius: var(--border-full)
    top: -65px
    left: -5px
    padding: 0
    cursor: default
    ::v-deep .option
      height: 100%
      width: 100%
      .item
        font-size: var(--xl-4-5)
  .body
    overflow-wrap: break-word

.chips
  display: flex
  flex-wrap: wrap
  gap: 5px
.diary-entry-cards
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 8px
.skeleton-row
  display: flex
  width: 100%
  max-width: 480px
  gap: 5px
</style>
